/* eslint-disable import/first */

import NextApp from 'next/app'

import { init as sentryInit } from '../lib/sentry.js'

if (process.browser) {
  sentryInit()
}

import { useEffect } from 'react'
import Head from 'next/head'
import { Box, ChakraProvider } from '@chakra-ui/react'
import { I18nextProvider, useTranslation } from 'react-i18next'

import { release, siteTitle, siteSocialImage, siteTwitter } from '../config.js'
import {
  getI18nForLanguage,
  getLanguage,
  preloadI18nResources
} from '../lib/i18n.js'
import { fetcher } from '../lib/fetcher.js'
import { theme } from '../theme/index.js'

import { useBrowser } from '../hooks/useBrowser.js'
import { useInstallPrompt } from '../hooks/useInstallPrompt.js'
import { useAnalytics } from '../hooks/useAnalytics.js'
import { useToast } from '../hooks/useToast.js'

import { WormholeContext } from '../components/WormholeContext.js'

globalThis.theme = theme
const { primaryColor } = theme.site

export default function MyApp ({ Component, pageProps, err }) {
  let {
    title,
    i18nTitle,
    description,
    socialImage = siteSocialImage,
    initialContext,
    initialLanguage,
    preloadedI18nResources,
    ...props
  } = pageProps

  // Register analytics as early as possible
  useAnalytics()

  const [isInstallable, showInstallPrompt] = useInstallPrompt()

  const i18n = getI18nForLanguage(initialLanguage, preloadedI18nResources)

  if (Array.isArray(i18nTitle) && i18nTitle.length > 0) {
    title = i18n.t(...i18nTitle)
  }

  if (title == null) title = `${siteTitle} - ${i18n.t('site.shortDescription')}`
  else title += ` - ${siteTitle}`

  if (description == null) description = i18n.t('site.description')

  return (
    <I18nextProvider i18n={i18n}>
      <ChakraI18nProvider theme={theme}>
        <WormholeContext.Provider
          value={{ isInstallable, showInstallPrompt, ...initialContext }}
        >
          <App>
            {/* Workaround for https://github.com/vercel/next.js/issues/8592 */}
            <Component {...props} err={err} />
          </App>
          <Head>
            <title>{title}</title>
            <meta
              name='viewport'
              content='width=device-width, initial-scale=1, viewport-fit=cover'
            />
            <meta name='description' content={description} />

            <meta
              name='apple-mobile-web-app-status-bar-style'
              content='black-translucent'
            />
            <meta name='application-name' content={siteTitle} />
            <link
              rel='apple-touch-icon'
              sizes='180x180'
              href='/apple-touch-icon.png'
            />
            <link
              rel='icon'
              type='image/png'
              sizes='32x32'
              href='/favicon-32x32.png'
            />
            <link
              rel='icon'
              type='image/png'
              sizes='16x16'
              href='/favicon-16x16.png'
            />
            <link rel='manifest' href='/site.webmanifest' />
            <link
              rel='mask-icon'
              href='/safari-pinned-tab.svg'
              color={primaryColor}
            />
            <meta name='msapplication-TileColor' content={primaryColor} />
            <meta name='theme-color' content={primaryColor} />

            <meta property='og:title' content={title} />
            <meta property='og:description' content={description} />
            <meta property='og:image' content={socialImage} />
            <meta property='og:site_name' content={siteTitle} />
            <meta property='og:type' content='website' />

            <meta name='twitter:title' content={title} />
            <meta name='twitter:image' content={socialImage} />
            <meta name='twitter:site' content={siteTwitter} />
            <meta name='twitter:card' content='summary_large_image' />

            <link rel='icon' href='/favicon.ico' />
          </Head>
        </WormholeContext.Provider>
      </ChakraI18nProvider>
    </I18nextProvider>
  )
}

const ChakraI18nProvider = ({ theme, ...rest }) => {
  const { i18n } = useTranslation()
  const direction = i18n.dir()
  const i18nTheme = Object.assign({}, theme, { direction })

  return <ChakraProvider theme={i18nTheme} {...rest} />
}

/**
 * App-level hooks and components that depend on global providers and context.
 */
const App = props => {
  const { t } = useTranslation()
  const browser = useBrowser()
  const toast = useToast()

  // Periodically check if this release is bad so we can reload the browser
  useEffect(() => {
    const interval = setInterval(async () => {
      let checkData
      try {
        checkData = await fetcher('/api/check', {
          query: {
            release
          }
        })
      } catch (err) {
        return // Ignore error
      }

      // Forcefully reload the page
      if (checkData.reload) {
        window.location.reload()
      }
    }, 10 * 60_000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  // Warn user of an unsupported or outdated browser
  useEffect(() => {
    if (browser.isUnsupported) {
      toast({
        title: t('app.unsupportedBrowserToastTitle'),
        description: t('app.unsupportedBrowserToastDescription'),
        status: 'error'
      })
    } else if (browser.isOutdated) {
      toast({
        title: t('app.oldBrowserToastTitle'),
        description: t('app.oldBrowserToastDescription'),
        status: 'error'
      })
    }
  }, [t, toast, browser])

  // Install service worker
  useEffect(() => {
    if (!('serviceWorker' in navigator)) return

    navigator.serviceWorker
      .register('/service-worker.js', { updateViaCache: 'none' })
      .catch(() => {})
  }, [browser])

  return <Box {...props} />
}

MyApp.getInitialProps = async appContext => {
  // calls page's `getInitialProps` and fills `appProps.pageProps`
  const { pageProps, ...restAppProps } = await NextApp.getInitialProps(
    appContext
  )

  const initialLanguage = getLanguage(appContext.ctx.req)
  const preloadedI18nResources = await preloadI18nResources(initialLanguage)

  return {
    pageProps: {
      ...pageProps,
      initialLanguage,
      preloadedI18nResources,
      initialContext: {
        userAgent: appContext.ctx.req?.headers['user-agent']
      }
    },
    ...restAppProps
  }
}
