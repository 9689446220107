import { useEffect, useState } from 'react'

import { browserDetect } from '../lib/browser-detect.js'
import { logEvent } from '../lib/analytics.js'

export const useInstallPrompt = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null)

  const isInstallable = !!deferredPrompt

  const showInstallPrompt = () => {
    if (!deferredPrompt) return
    deferredPrompt.prompt()
  }

  useEffect(() => {
    const handleBeforeInstallPrompt = event => {
      // Prevent the mini-infobar from appearing on mobile
      event.preventDefault()

      // Stash the event so it can be triggered later
      setDeferredPrompt(event)
    }

    const handleAppInstalled = () => {
      const { os } = browserDetect()
      logEvent('install', { os })
    }

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt)
    window.addEventListener('appinstalled', handleAppInstalled)

    return () => {
      window.removeEventListener(
        'beforeinstallprompt',
        handleBeforeInstallPrompt
      )
      window.removeEventListener('appinstalled', handleAppInstalled)
    }
  }, [])

  return [isInstallable, showInstallPrompt]
}
