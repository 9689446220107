import {
  extendTheme,
  theme as chakraTheme,
  withDefaultColorScheme
} from '@chakra-ui/react'

import { styles } from './styles.js'

import { Tooltip } from './components/Tooltip.js'

const colorScheme = 'purple'

export const theme = extendTheme(
  {
    config: {
      initialColorMode: 'dark',
      useSystemColorMode: false
    },
    site: {
      colorScheme,
      primaryColor: chakraTheme.colors[colorScheme]['400']
    },
    styles,
    components: {
      Tooltip
    }
  },
  withDefaultColorScheme({ colorScheme })
)
