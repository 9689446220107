import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { logPageview } from '../lib/analytics.js'

export const useAnalytics = () => {
  const router = useRouter()

  useEffect(() => {
    let hasFirstPageview = false

    const handlePageview = () => {
      hasFirstPageview = true
      logPageview()
    }

    const handleVisibilityChange = () => {
      if (!hasFirstPageview && document.visibilityState === 'visible') {
        handlePageview()
      }
    }

    const handleRouteChangeComplete = () => {
      handlePageview()
    }

    if (document.visibilityState !== 'prerender') {
      handlePageview()
    }

    router.events.on('routeChangeComplete', handleRouteChangeComplete)
    document.addEventListener('visibilitychange', handleVisibilityChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete)
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
}
