export const Tooltip = {
  sizes: {
    md: {
      px: '8px',
      py: '2px',
      fontSize: 'sm',
      borderRadius: 'sm'
    },
    lg: {
      px: '12px',
      py: '6px',
      fontSize: 'md',
      borderRadius: 'md'
    }
  },
  defaultProps: {
    size: 'md'
  }
}
