// The environment the site is running in
export const environment = process.env.NODE_ENV || 'development'

// Is the site running in production?
export const isProd = environment === 'production'

// Is the site running in a pull request?
export const isPr = process.env.IS_PULL_REQUEST === 'true'

// Is the site running on Render?
export const isRender = process.env.RENDER === 'true'

// Enable test keyboard shortcuts?
export const enableTestShortcuts =
  !isProd || process.env.ENABLE_TEST_SHORTCUTS === 'true'

// The git commit of the running site
export const release = process.env.RENDER_GIT_COMMIT || 'development'

// Server port
export const port = Number(process.env.PORT) || 4000

// IP address to listen on
export const bindAddress = isRender ? '0.0.0.0' : '127.0.0.1'

// Hostname on Render
const renderHostname = isPr
  ? process.env.RENDER_EXTERNAL_HOSTNAME
  : process.env.HOSTNAME

// Website hostname + port
export const host = isRender ? renderHostname : `localhost:${port}`

// Website protocol
export const protocol = isRender ? 'https' : 'http'

// Website origin (scheme + hostname + port)
export const origin = `${protocol}://${host}`

const wsProtocol = isRender ? 'wss' : 'ws'

// Websocket origin (scheme + hostname + port)
export const wsOrigin = `${wsProtocol}://${host}`

// Websocket URL
export const wsUrl = `${wsOrigin}/websocket`

// Title of the site
export const siteTitle = 'Wormhole'

// Description of the site
export const siteDescription =
  'Wormhole lets you share files with end-to-end encryption and a link that automatically expires.'

// Twitter username of the site
export const siteTwitter = 'Wormhole_App'

export const siteTwitterUrl = `https://twitter.com/${siteTwitter}`

// Discord invite link for the site
export const siteDiscord = 'https://discord.gg/de6FscsK5Z'

// Site logo
export const siteLogo = `${origin}/images/logo-80x80.png`

// Default image to represent site on social networks
export const siteSocialImage = `${origin}/images/social-share-home.jpg`

// Root path of project
export const rootPath = process.cwd()

// Maximum room size displayed to user
export const maxRoomSizeGb = 10

// Maximum cloud room size displayed to user
export const maxRoomCloudSizeGb = 5

// Maximum room size in bytes
export const maxRoomSize = maxRoomSizeGb * 1e9

// Maximum room size in bytes for cloud upload
export const maxRoomCloudSize = maxRoomCloudSizeGb * 1e9 * 1.1

// Room lifetime by default
export const defaultRoomLifetimeSeconds = 24 * 3600 // One day

// Maximum downloads per room by default
export const defaultMaxRoomDownloads = 100

// Values for lifetime (1 hour, 2 hours, 6 hours, 12 hours, 24 hours)
export const roomLifetimeValues = [1, 2, 6, 12, 24].map(h => h * 3600)

// Values for max downloads
export const maxRoomDownloadsValues = [1, 5, 10, 20, 50, 100]

// Time between each uploader ping
export const uploaderPingIntervalSeconds = 30

// WebRTC configuration
export const rtcConfig = {
  iceServers: [
    {
      urls: ['stun:relay.wormhole.app:443']
    },
    {
      urls: [
        'turn:relay.wormhole.app:443?transport=udp',
        'turn:relay.wormhole.app:443?transport=tcp',
        'turns:relay.wormhole.app:443?transport=tcp'
      ],
      username: 'relay.wormhole.app',
      credential: 'tears-whiplash-overall-diction'
    }
  ],
  sdpSemantics: 'unified-plan',
  bundlePolicy: 'max-bundle',
  iceCandidatePoolsize: 1
}

// Backblaze B2 bucket information
let b2Config
if (isRender) {
  if (isPr) {
    // The service name is a unique slug per PR
    b2Config = {
      bucketName: 'socket-dev-test',
      bucketId: 'a1c0dd5f77b7bd8a7c780a12',
      pathPrefix: `preview-${process.env.RENDER_SERVICE_NAME}`
    }
  } else {
    b2Config = {
      bucketName: 'socket-dev-prod',
      bucketId: 'a160ed1fa777fdea7c780a12',
      pathPrefix: null
    }
  }
} else {
  // dev/ci environments
  b2Config = {
    bucketName: 'socket-dev-test',
    bucketId: 'a1c0dd5f77b7bd8a7c780a12',
    // If B2_PATH_PREFIX isn't defined, use the dev's local username
    // to prevent collisions
    pathPrefix: process.env.B2_PATH_PREFIX ?? `dev-${process.env.USER}`
  }
}
export { b2Config }

// Content-Security-Policy reporting URL
export const cspReportUrl = `https://o555220.ingest.sentry.io/api/5684834/security/?sentry_key=a47523bda986460eb5242137b48829e0&sentry_release=${release}&sentry_environment=${environment}`
