export const styles = {
  global: {
    html: {
      height: '100%'
    },
    body: {
      minHeight: '100%',
      // Prevent margin on children from collapsing (and passing through)
      // <body> margin
      padding: '.1px'
    }
  }
}
